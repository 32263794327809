import LocalisedDate from "@components/LocalisedDate";
import { AedDate, AedText, AedYesNo } from "@components/WizardIcons";
import { DateFormat } from "@util/dateFormat";

import { IIcon } from "../icons";

export const getInspectionRunIcon = (run: Api.AedInspectionRun): IIcon => {
  switch (run.AedInspectionCheck.Type) {
    case "Date":
      return "calendar-check";
    default:
      return "check-alt-circle";
  }
};

export const getInspectionTypeIcon = (
  type: Api.AedInspectionCheck["Type"],
  size?: number,
) => {
  switch (type) {
    case "Date":
      return <AedDate size={size} />;
    case "Yes/No":
      return <AedYesNo size={size} />;
    case "Text":
      return <AedText size={size} />;
  }
};

export const getInspectionRunResult = (run: Api.AedInspectionRun) => {
  switch (run.AedInspectionCheck.Type) {
    case "Date":
      return (
        <LocalisedDate
          dateTime={run.InspectionResult}
          format={DateFormat.short}
        />
      );
    case "Text":
    case "Yes/No":
      return run.InspectionResult;
    default:
      return "Completed";
  }
};
