import { useTheme } from "styled-components";

import GroupHeader from "@components/GroupHeader";
import Icon from "@components/Icon";
import ListItem from "@components/ListItem";
import MediaCard, { MediaCardPlaceholder } from "@components/MediaCard";
import Message from "@components/Message";
import StoreTime from "@components/StoreTime";
import { AedUpdate } from "@components/WizardIcons";
import { UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useUnitFull } from "@state/hooks";
import { DateFormat } from "@util/dateFormat";
import { getInspectionRunIcon } from "@util/inspections";
import { EscalationProps } from "@util/viewModel";

const AedInspection = ({
  escalation,
}: EscalationProps<Api.AedInspectionEscalation>) => {
  const { palettes } = useTheme();

  const { data: unit } = useUnitFull(escalation.ControllerSerialNumber);

  return (
    <div
      style={{
        overflow: "hidden",
        padding: `${DS.margins.micro} ${DS.margins.regular}`,
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <Message type="info">
        <strong>{unit?.UnitName ?? "This AED"}</strong> has inspection checks
        due. Begin inspection to complete the checks.
      </Message>

      {!unit ? (
        <MediaCardPlaceholder imageSize={48} />
      ) : (
        <>
          <MediaCard
            image={<AedUpdate />}
            imageSize={48}
            title={unit.UnitName}
            description={unit.StoreName}
          />

          <div>
            <GroupHeader>Checks to Complete</GroupHeader>
            <UnstyledList>
              {unit.AedInspectionRuns.filter((run) => run.IsDue).map((run) => (
                <ListItem
                  key={run.AedInspectionRunId}
                  image={
                    <Icon
                      name={getInspectionRunIcon(run)}
                      color={palettes.states.neutral.background}
                    />
                  }
                  title={run.AedInspectionCheck.Label}
                  description={run.AedInspectionCheck.Description}
                />
              ))}
            </UnstyledList>
          </div>

          <div>
            <GroupHeader>Recently Completed Checks</GroupHeader>
            <UnstyledList>
              {unit.AedInspectionRuns.sort(
                (a, b) =>
                  new Date(b.ModifiedDate).getTime() -
                  new Date(a.ModifiedDate).getTime(),
              ).map((run) => (
                <ListItem
                  key={run.AedInspectionRunId}
                  image={
                    <Icon
                      name={getInspectionRunIcon(run)}
                      color={palettes.body.small}
                    />
                  }
                  title={run.AedInspectionCheck.Label}
                  description={
                    <span>
                      By {run.LastModifiedByUserName} on{" "}
                      <StoreTime
                        storeName={unit.StoreName}
                        dateTime={run.ModifiedDate}
                        format={DateFormat.short}
                      />
                    </span>
                  }
                />
              ))}
            </UnstyledList>
          </div>
        </>
      )}
    </div>
  );
};

export default AedInspection;
