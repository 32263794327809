import { FzfResultItem } from "fzf";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

export type UnitStockStatus =
  | "stocked"
  | "in-transit"
  | "needs-refill"
  | "critical";

export type Active = "online" | "offline" | null;

export type Ready = "not-ready" | "ready" | null;

export type DaysGone = "1" | "0" | null;

export type Filterable = {
  id: string;
  name: string;
};

const DAYS_GONE_CUTOFF = 29;

export type UnitFilter = {
  escalationTypes: Api.UnitReminderType[];
  stockLevels: UnitStockStatus[];
  active: Active;
  readiness: Ready;
  daysGone: DaysGone;
  unitTypeFilter: Filterable[];
  storesFilter: Filterable[];
};

export const useUnitsFilter = (): [
  UnitFilter,
  (
    filter:
      | Partial<UnitFilter>
      | ((current: UnitFilter) => Partial<UnitFilter>),
  ) => void,
] => {
  const { search } = useLocation();

  const [filters, setFilters] = useState<UnitFilter>(() => {
    const query = new URLSearchParams(search);

    const stockLevelsString = query.get("stock-level") ?? "";

    return {
      daysGone: query.get("days-since") as DaysGone,

      readiness: query.get("readiness") as Ready,

      active: query.get("active") as Active,
      stockLevels: stockLevelsString
        ? (stockLevelsString.split(",") as UnitStockStatus[])
        : [],

      escalationTypes: (query.get("action-types")?.split(",") ??
        []) as Api.UnitReminderType[],

      unitTypeFilter: [] as Filterable[],
      storesFilter: [] as Filterable[],
    };
  });

  const setUnitFilter = useCallback(
    (
      filter:
        | Partial<UnitFilter>
        | ((current: UnitFilter) => Partial<UnitFilter>),
    ) => {
      const newFilter = typeof filter === "function" ? filter(filters) : filter;
      setFilters((currentFilter) => ({
        ...currentFilter,
        ...newFilter,
      }));
    },
    [filters],
  );

  return [filters, setUnitFilter];
};

export const filter_daysGone =
  (enabled: boolean, stores?: Api.Store[]) =>
  (unit: FzfResultItem<Api.Unit>) =>
    enabled && stores
      ? stores
          .filter((store) => store.StoreId === unit.item.StoreId)
          .filter(
            (store) =>
              Date.now() -
                new Date(store.LastHighSeverityEventDate ?? 0).getTime() <
              1000 * 60 * 60 * 24 * DAYS_GONE_CUTOFF,
          ).length > 0
      : true;

export const filter_escalations =
  (
    enabled: boolean,
    escalationTypes?: Api.UnitReminderType[],

    escalations?: Api.Escalation[],
  ) =>
  (unit: FzfResultItem<Api.Unit>) =>
    enabled && escalationTypes && escalations
      ? escalations
          .filter(
            (escalation) =>
              escalation.ControllerSerialNumber ===
              unit.item.ControllerSerialNumber,
          )
          .filter((escalation) =>
            escalationTypes.includes("AedInspection")
              ? escalation.ReminderType === "AedInspection" ||
                escalation.ReminderType === "AedPartsReplacement" ||
                escalation.ReminderType === "AedPartsReplacementConfirmation"
              : escalationTypes.includes("MissingTool")
                ? escalation.ReminderType === "MissingTool" ||
                  escalation.ReminderType === "PutMultiUseItemBackIntoKit"
                : escalationTypes.includes(escalation.ReminderType),
          ).length > 0
      : true;

const getReplenishmentStatus = (unit: Api.Unit) => {
  if (
    unit.ReplenishmentStatus === "Warehouse Packing " ||
    unit.ReplenishmentStatus === "Required "
  ) {
    return "needs-refill";
  }
  if (
    unit.IsStockCritical ||
    unit.ReplenishmentStatus === "Required (Critical)" ||
    unit.ReplenishmentStatus === "Signed-Off, Pack-List Sent (Critical)" ||
    unit.ReplenishmentStatus === "Signed-Off (Critical)" ||
    unit.ReplenishmentStatus === "Warehouse Packing (Critical)"
  ) {
    return "critical";
  }
  if (
    unit.ReplenishmentStatus === "Delivered " ||
    unit.ReplenishmentStatus === "Overdue Unpacking  (Delivered)" ||
    unit.ReplenishmentStatus === "Overdue Unpacking (Critical) (Delivered)" ||
    unit.ReplenishmentStatus === "Overdue Unpacking " ||
    unit.ReplenishmentStatus === "Overdue Unpacking (Critical)" ||
    unit.ReplenishmentStatus === "Overdue Unpacking  (Not Delivered)" ||
    unit.ReplenishmentStatus === "Sent In-Transit " ||
    unit.ReplenishmentStatus === "Sent In-Transit (Critical)" ||
    unit.ReplenishmentStatus === "Delivered (Critical)" ||
    unit.ReplenishmentStatus === "Unpacking " ||
    unit.ReplenishmentStatus === "Unpacking (Critical)"
  ) {
    return "in-transit";
  } else if (unit.ReplenishmentStatus === "") {
    return "stocked";
  }
};

export const filter_stockLevels =
  (stockStatus: UnitStockStatus[]) => (unit: FzfResultItem<Api.Unit>) => {
    if (!stockStatus || stockStatus.length === 0) {
      return true;
    }

    const unitStatus = getReplenishmentStatus(unit.item);
    if (unitStatus !== undefined) return stockStatus.includes(unitStatus);
  };

export const filter_active =
  (active: Active) => (unit: FzfResultItem<Api.Unit>) => {
    if (!active) {
      return true;
    }

    return active.includes(unit.item.IsActive ? "online" : "offline");
  };

export const filter_readiness =
  (readiness: Ready) => (unit: FzfResultItem<Api.Unit>) => {
    if (!readiness) {
      return true;
    } else {
      return readiness === "not-ready"
        ? !unit.item.ReadinessIsReady
        : unit.item.ReadinessIsReady;
    }
  };

export const getUnitTypes = (units: Api.Unit[]): Api.UnitType[] =>
  units
    ?.map((unit) => unit.UnitType)
    .filter((type, index, array) => array.indexOf(type) === index);
