import { useFormContext } from "react-hook-form";

import {
  LabelledDatePicker,
  LabelledInput,
  LabelledSelect,
} from "@components/FormControls";
import {
  WizardControlsContainer,
  WizardIconContainer,
  WizardStepContainer,
} from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";
import { getInspectionTypeIcon } from "@util/inspections";
import { AedInspection } from "@util/viewModel";

const CheckStep = ({ index }: { index: number }) => {
  const { register, watch } = useFormContext<AedInspection>();

  const run = watch(`ChecksCompleted.${index}`);

  return (
    <WizardStepContainer>
      <ModalDescription>{run.AedInspectionCheck.Description}</ModalDescription>

      <WizardControlsContainer>
        <WizardIconContainer>
          {getInspectionTypeIcon(run.AedInspectionCheck.Type, 96)}
        </WizardIconContainer>

        {run.AedInspectionCheck.Type === "Date" ? (
          <LabelledDatePicker
            label={run.AedInspectionCheck.Label}
            {...register(`ChecksCompleted.${index}.InspectionResult`)}
          />
        ) : run.AedInspectionCheck.Type === "Text" ? (
          <LabelledInput
            label={run.AedInspectionCheck.Label}
            {...register(`ChecksCompleted.${index}.InspectionResult`)}
          />
        ) : (
          <LabelledSelect
            label={run.AedInspectionCheck.Label}
            defaultValue="incomplete"
            options={[
              { label: "No", value: "No" },
              { label: "Yes", value: "Yes" },
            ]}
            {...register(`ChecksCompleted.${index}.InspectionResult`)}
          />
        )}
      </WizardControlsContainer>
    </WizardStepContainer>
  );
};

export default CheckStep;
